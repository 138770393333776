.buttonActive{
    bottom: 0px;
    right: 0px;
    margin: 50px;
    z-index: 2000;
    width: 50px;
    display: flex;
    position: fixed;
    transition: ease-in-out .5s;
}

.buttonDesactive{
    transition: ease-in-out .5s;
    bottom: -220px;
    right: 0px;
    margin: 50px;
    z-index: 2000;
    width: 50px;
    display: flex;
    position: fixed;
}

@media screen and (max-width: 992px) {
    .buttonActive{
        bottom: 0px;
        right: 0px;
        margin: 5px;
        z-index: 2000;
        width: 50px;
        display: flex;
        position: fixed;
        transition: ease-in-out .5s;
    }
    
    .buttonDesactive{
        transition: ease-in-out .5s;
        bottom: -220px;
        right: 0px;
        margin: 5px;
        z-index: 2000;
        width: 50px;
        display: flex;
        position: fixed;
    }
}