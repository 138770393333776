.footerContainer{
    height: 200px;
    background-color: rgba(2, 65, 142, 1);
    z-index: 100;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: rgba(165,165,165, 1);
}

.logoFooter{
    height: 100px;
}