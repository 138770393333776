.divContainer{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-evenly;
    z-index: 100;
    height: 60vh;
}

.messageNSubmit{
    display: none;
    width: 400px;
    height: 100px;
    align-items: center;
    background-color: rgb(0, 0, 0);
    position: absolute;
    justify-content: center;
    bottom: 10rem;
    border-radius: 5px;
    border: 5px solid rgb(3, 117, 161);
    color: rgb(3, 117, 161);
   

}
.messageSend{
    display: flex;
    
}
.fondoForm{
    display: flex;
    position: absolute;
    width: 100%;
    height: 60vh;
}

.newPlaceholder::placeholder{
    background-image: url('../img/vecteezy_red-hazard-warning-sign-on-transparent-background_17178088.png');
    background-repeat: no-repeat;
    background-position: 10px center; /* Ajustar la posición de la imagen */
    background-size: 20px;
    background-position: 0px 5px;
    color: red;
    position: relative;
    
   
    height: 100%;
    width: 100%;
    padding-top: 10px;
    padding-left: 25px;
    
   
}




.formContainer{
    display: flex;
    flex-direction: column;
    width: 55%;
    z-index: 100;
    align-items: center;
}
.formContainer{
    font-size: 20px;
    font-family: "Roboto Mono", monospace;
}
/*.preForm {
    font-size: 15px;
    font-family: "Roboto Mono", monospace;
    z-index: 200;
}*/
.inputForm {
    outline: none;
    width: 100%;
    height: 40px;
    margin: 10px;
    border-radius: 10px;
    border: 3px solid rgba(2, 65, 142, 1);
    font-size: 13px;
}

.formContainer textarea{
    outline: none;
    width: 100%;
    height: 80px;
    margin: 10px;
    border-radius: 10px;
    border: 3px solid rgba(2, 65, 142, 1);
    font-size: 13px;

}


.send{
    width: 50%;
    height: 40px;
    margin: 10px;
    border-radius: 5px;
    border: 0px;
    cursor: pointer;
    box-shadow: 2px 2px 0px #000;
   }

.send:hover{
    /*background-color: rgb(151, 149, 240);*/
    transform: translateY(-2px);
    box-shadow: 4px 4px 0px #000;
}
.send:active{
    /*border: 3px solid rgba(2, 65, 142, 1);*/
    transform: translateY(2px);
    box-shadow: 0px 0px 0px #000;
}


/*.contactContainer img{
    width: 100px;
    margin-left: 50px;
    margin-bottom: 50px;
    z-index: 200;
}
.contactContainer{
    display: flex;
    flex-direction: column;
}*/

@media screen and (max-width : 576px){
    .divContainer{
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-evenly;
        z-index: 100;
        height: 50vh;
    } 
    .fondoForm{
        display: flex;
        position: absolute;
        width: 100%;
        height: 50vh;
    }
}

@media screen and (max-width: 992px){
    .divContainer{
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-evenly;
        z-index: 100;
        height: 60vh;
    }

    .fondoForm{
        display: flex;
        position: absolute;
        width: 100%;
        height: 60vh;
    }
    
   
   /* .preForm{
    display: flex;
    z-index: 100;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    align-items: center;

   }
   .preForm h2{
    font-size: 20px;
    font-family: "Roboto Mono", monospace;
   }
   .contactContainer{
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;    
    width: 100%;
    
   
   }
   .contactContainer img{
    
    width: 60px;
    height: 60px;
    margin-left: 0px;
    margin-bottom: 0px;
    
   }*/
   .formContainer {
    display: flex;
    text-align: center;
    width: 80%;
   }
   .inputForm {
    width: 100%;
    height: 40px;
    margin: 10px;
    border-radius: 10px;
    border: 3px solid rgba(2, 65, 142, 1);
}

.send{
    width: 50%;
    height: 40px;
    margin: 10px;
    border-radius: 5px;
   
   }

.send:hover{
 background-color: #fff;
 box-shadow: 2px 2px 0px #000;
 transform: translateY(0px);
}
.messageNSubmit{
    width: 300px;
}
}

@media screen and (max-height: 576px) {
        .divContainer{
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-evenly;
        z-index: 100;
        height: 50vh;
    }

    .fondoForm{
        display: flex;
        position: absolute;
        width: 100%;
        height: 50vh;
    }
    
   
    /*.preForm{
    display: none;
    z-index: 100;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    align-items: center;

   }
   
   .contactContainer{
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;    
    width: 100%;
    
   
   }*/
   
   .formContainer {
    display: flex;
    text-align: center;
    width: 80%;
   }
   .inputForm {
    width: 100%;
    height: 20px;
    margin: 10px;
    border-radius: 10px;
    border: 3px solid rgba(2, 65, 142, 1);
}

.send{
    width: 20%;
    height: 20px;
    margin: 10px;
    border-radius: 5px;
    border: 3px solid rgba(2, 65, 142, 1);
   }
   .formContainer textarea{
    width: 100%;
    height: 40px;
    margin: 10px;
    border-radius: 10px;
   
    font-size: 13px;

}
.send:hover{
    background-color: #fff;
    box-shadow: 2px 2px 0px #000;
    transform: translateY(0px);
}
}