.seccionContainer{
    width: 100%;
    height: 110vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    
}
.fondo{
    right: 0px;
    display: flex;
    height: 110vh;
    position: absolute;
    width: 100%;
    z-index: 1;

}
a button{
    height: 100%;
    cursor: pointer;
    box-shadow: 5px 5px 0px #000;
    border-radius: 10px;
    border: 2px solid  rgb(2, 44, 97);
    background-color: rgba(2, 165, 142, 1);
    color: rgb(255, 255, 255);
    transition: all 0.3s ease;
    
}
a button:hover{
    background-color: #fff;
    color: rgba(2, 165, 142, 1);
    box-shadow:5px 5px 0px rgba(2, 165, 142, 1);
}
a button:active{
    background-color: rgba(2, 165, 142, 1);
    box-shadow: none;
    transform: translateY(4px);
    color: #fff;
}

.info {
    
    height: 40px;
}

.title{
    display: flex;
    z-index: 50;
    font-size: 20px;
    color: rgb(169, 169, 169);
}

.dataContainer{
    position: relative;
    z-index: 200;
    display: flex;
    flex-direction: column;
    width: 70%;
    justify-content: center;
    align-items: center;
}
.dataContainer img{
    margin-top: 100px;
    width: 300px;
   
    
}
.parrafoContainer{
    display: flex;
    flex-direction: column;
    width: 60%;
    justify-content: center;
    align-items: center;
   
    
    
}

.parrafoContainer p {
    font-family: "Oswald", sans-serif;
    font-size: 18px;
    line-height: 20px;
    margin-top: 90px;
    margin-bottom: 40px;
}







.cardAnimation{
    margin-top: 5px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    width: 300px;
    height: 50px;
    background: rgb(2, 30, 46);
    position: relative;
    overflow: hidden;
    border-radius: 20px;
    box-shadow: 5px 5px 5px #000;
    z-index: 50;
    
   
}

.cardAnimation::before{
    content: '';
    position: absolute;
    width: 600px;
    background-image: linear-gradient(180deg, rgb(0, 183, 255), rgb(255, 48, 255));
    height: 25px;
    animation: rotBGimg 3s linear infinite;
    transition: all 0.2s linear;
    


}
@keyframes rotBGimg {
    from {
    transform: rotate(0deg);
    }

    to {
    transform: rotate(360deg);
    }
}

.cardAnimation::after {
    content: '';
    position: absolute;
    background:  rgba(2, 65, 142, 1);
    inset: 5px;
    border-radius: 15px;
    

}






@media screen and (max-width : 576px) {

    a button:hover{
        background-color: rgba(2, 165, 142, 1);
    color: rgb(255, 255, 255);
    box-shadow:5px 5px 0px #000;
}
    
    .seccionContainer{
        width: 100%;
        height: 110vh;
        display: flex;
        flex-direction: column;
        align-items: center;
        
    }
    .fondo{
        right: 0px;
        display: flex;
        height: 110vh;
        position: absolute;
        width: 100%;
        z-index: 1;

    }
    .info {
        
        height: 40px;
    }

    
    
    
    .title{
        display: flex;
        z-index: 50;
        font-size: 20px;
        color: rgb(169, 169, 169);
       
    }

    .dataContainer{
        position: relative;
        z-index: 200;
        display: flex;
        flex-direction: column;
        width: 70%;
        justify-content: center;
        align-items: center;
        
    }
    .dataContainer img{
        margin-top: 50px;
        width: 300px;
        margin-bottom: 2rem;
       
        
    }
    .parrafoContainer{
        display: flex;
        flex-direction: column;
        width: 100%;
        justify-content: center;
        align-items: center;
        
    }

    .parrafoContainer p {
        font-family: "Oswald", sans-serif;
        font-size: 18px;
        line-height: 20px;
        margin-top: 50px;
        margin-bottom: 4rem;
        
    }







    .cardAnimation{
        margin-top: 5px;
        margin-bottom: 2rem;
        display: flex;
        justify-content: space-around;
        align-items: center;
        width: 300px;
        height: 50px;
        background: rgb(2, 30, 46);
        position: relative;
        overflow: hidden;
        border-radius: 20px;
        box-shadow: 5px 5px 5px #000;
        z-index: 50;
        
       
    }

    .cardAnimation::before{
        content: '';
        position: absolute;
        width: 600px;
        background-image: linear-gradient(180deg, rgb(0, 183, 255), rgb(255, 48, 255));
        height: 25px;
        animation: rotBGimg 3s linear infinite;
        transition: all 0.2s linear;
        


    }
    @keyframes rotBGimg {
        from {
        transform: rotate(0deg);
        }
    
        to {
        transform: rotate(360deg);
        }
    }
    
    .cardAnimation::after {
        content: '';
        position: absolute;
        background:  rgba(2, 65, 142, 1);
        inset: 5px;
        border-radius: 15px;
        

    }
   
}

@media screen and (max-width : 992px){
    .dataContainer img{
        margin-top: 50px;
        width: 300px;
       
        
    }
    .parrafoContainer{
        display: flex;
        flex-direction: column;
        width: 80%;
        justify-content: center;
        align-items: center;
        
    }

    .parrafoContainer p {
        font-family: "Oswald", sans-serif;
        font-size: 18px;
        line-height: 20px;
        margin-top: 50px;
    }
    a button:hover{
        background-color: rgba(2, 165, 142, 1);
        color: rgb(255, 255, 255);
        box-shadow: 5px 5px 0px #000;
    }
}

@media screen and (max-height: 576px) {
    .seccionContainer{
        width: 100%;
        height: 125vh;
        display: flex;
        flex-direction: column;
        align-items: center;
        
    }
    .fondo{
        right: 0px;
        display: flex;
        height: 125vh;
        position: absolute;
        width: 100%;
        z-index: 1;

    }
    .info {
        
        height: 40px;
    }

    
    
    
    .title{
        display: flex;
        z-index: 50;
        font-size: 20px;
        color: rgb(169, 169, 169);
    }

    .dataContainer{
        position: relative;
        z-index: 200;
        display: flex;
        flex-direction: column;
        width: 70%;
        justify-content: center;
        align-items: center;
    }
    .dataContainer img{
        margin-top: 50px;
        width: 300px;
       
        
    }
    .parrafoContainer{
        display: flex;
        flex-direction: column;
        width: 100%;
        justify-content: center;
        align-items: center;
        
    }

    .parrafoContainer p {
        font-family: "Oswald", sans-serif;
        font-size: 15px;
        line-height: 20px;
        margin-top: 50px;
    }







    .cardAnimation{
        margin-top: 5px;
        display: flex;
        justify-content: space-around;
        align-items: center;
        width: 150px;
        height: 40px;
        background: rgb(2, 30, 46);
        position: relative;
        overflow: hidden;
        border-radius: 20px;
        box-shadow: 5px 5px 5px #000;
        z-index: 50;
        
       
    }

    .dataContainer img{
        margin-top: 20px;
        width: 150px;
       
        
    }

    
   
}