
main{
  
  position: relative;
}
header{
  display: flex;
  position: sticky;
  top: 0;
  z-index: 800;
}