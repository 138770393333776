.whatsapp{
    z-index:2000;
    bottom: 0px;
    width:50px;
    display:flex;
    position:fixed; 
    margin: 50px

}

@media screen and (max-width: 992px) {
    .whatsapp{
        z-index:2000;
        bottom: 0px;
        width:50px;
        display:flex;
        position:fixed; 
        margin: 5px
    
    }
}
