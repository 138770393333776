@import url(/node_modules/swiper/swiper-bundle.min.css);


.swiper-button-next, .swiper-button-prev{
    top:50%;
    padding: 5px 100px;
    display: none;
}
.swiper .swiper-slide {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 90vh;
    
    }
    button{
        height: 10vh;
    }
    .swiper-slide {
        font-size: 50px;
        color: rgb(169, 169, 169);
        
        
        font-family: 'Times New Roman', Times, serif;
      }
      h1{
        font-size: 30px;
      }
      
      


@media screen and (min-width: 576px) {
    .swiper-container {
    width: 576px;
    }
    .swiper .swiper-slide {
        display: flex;
        justify-content: center;
        align-items: center;
        width: auto;
        height: 90vh;
        
        }
        button{
            height: 10vh;
        }
        .swiper-slide {
            font-size: 98px;
            color: rgb(169, 169, 169);
            
            
            font-family: 'Times New Roman', Times, serif;
          }
          h1{
            font-size: 50px;
        }
          
}

@media screen and (min-width: 768px) {
    .swiper-container {
    width: 768px;
    }
    .swiper .swiper-slide {
        display: flex;
        justify-content: center;
        align-items: center;
        width: auto;
        height: 90vh;
        
        }
        button{
            height: 10vh;
        }
        .swiper-slide {
            font-size: 98px;
            color: rgb(169, 169, 169);
            
            
            font-family: 'Times New Roman', Times, serif;
          }
          h1{
            font-size: 50px;
        }
          
}

@media (min-width: 992px) {
    .swiper .swiper-slide {
    display: flex;
    justify-content: center;
    align-items: center;
    width: auto;
    height: 90vh;
    
    }
    h1{
        font-size: 90px;
    }
    button{
        height: 10vh;
    }
    .swiper-slide {
        font-size: 98px;
        color: rgb(169, 169, 169);
        
        
        font-family: 'Times New Roman', Times, serif;
      }
      
}