.active{
    width: 100%;
    height: 80px;
    background-color: rgba(2, 65, 142, 1);
    display: flex;
    position: sticky;
    top: 0px;
    z-index: 400;
    opacity: 0.5;
    color: rgba(165,165,165, 1);
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}
.desactive{
    height: 80px;
    width: 100%;
    background-color: rgba(2, 65, 142, 1);
    display: flex;
    position: sticky;
    top: 0px;
    z-index: 400;
    color: rgba(165,165,165, 1);
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
     
}
.active:hover{
    background-color: rgba(2, 65, 142, 1);
    color: rgba(165,165,165, 1);
    opacity: 1;
    

}
.logo{
    width: 60px;
    z-index: 600;
    margin: 15px;
}

.wrappActive{
    display: absolute;

}
.menuImg{
    display: none;
    
}

.menu{
    
    display: flex;
    flex-direction: row;
    
    
    
}


.wrappActive{
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 80px;
    right: 150px;
    width: 150px;
    height: 155px;
    background-color: rgba(2, 65, 142, 1);
    border-radius: 0px 0px 15px 15px;
    transition: 1s all ease-in;


}
.wrappDesactive{
 display: none;
 position: absolute;
 top: 200px;
 transition: 1s all ease-in;
}
.itemLista{
    margin: 15px;
    text-decoration: none;
    list-style: none;
    color: rgba(165,165,165, 1);
    cursor: pointer;

}
.itemLista1{
    margin: 15px 25px 15px 15px;
    text-decoration: none;
    list-style: none;
    color: rgba(165,165,165, 1);
    cursor: pointer;
   

}
.itemLista:hover{
    color: aqua;
    text-shadow: 0 0 5px #fff, 
               0 0 10px #fff,  
               0 0 15px rgb(0, 195, 255),  
               0 0 20px rgb(0, 195, 255),
               0 0 25px rgb(0, 195, 255),
               0 0 30px rgb(0, 195, 255);
    
}
.itemLista1:hover{
    color: aqua;
    text-shadow: 0 0 5px #fff, 
               0 0 10px #fff,  
                0 0 15px rgb(0, 195, 255),  
               0 0 20px rgb(0, 195, 255),
               0 0 25px rgb(0, 195, 255),
               0 0 30px rgb(0, 195, 255);
    
}

.itemLista1 span{
    right: auto;
    font-size: 13px;
    margin-right: 10px;
    color: rgba(165,165,165, 1);
   position: absolute;
   width: 100px;
   right: 190px;
   
   height: 27px;
   text-align: end;
   vertical-align: end;
   line-height: 36px;
}
.itemLista1 span:hover{
    color: aqua;
    text-shadow: 0 0 5px #fff, 
               0 0 10px #fff,  
               0 0 15px rgb(0, 195, 255),  
               0 0 20px rgb(0, 195, 255),
               0 0 25px rgb(0, 195, 255),
               0 0 30px rgb(0, 195, 255);
    
}




@media screen  and (max-width: 992px){
    .menuWrap{
        display: flex;
        width: 50px;
        height: 50px;
        flex-direction: column;
        justify-content: center;
       
    }
    .linea1D, .linea2D, .linea3D{
        margin: 3px;
        width: 50px;
        height: 5px;
        background-color: rgba(165,165,165, 1);
        transition: all 0.5s;
    }
    .linea1{
        margin: 0px;
        width: 50px;
        height: 5px;
        background-color: rgba(165,165,165, 1);
        transform: rotate(45deg);
        transition: all 1s;
    }
    .linea2{
        position: relative;
        margin: 0px;
        width: 50px;
        height: 5px;
        background-color: rgba(165,165,165, 1);
        transform: rotate(135deg);
        bottom: 5.1px;
        transition: all 1s;
       
    }
    .linea3{
        display: none;
        transition: all 1s;
    }
    .menu{
        display: flex;
        flex-direction: column;
    }
    .menuImg{
        display: flex;
        width: 15px;
    }
    .active{
        height: 80px;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        padding-inline: 10px;
        background-color: rgba(2, 65, 142, 1);
       
        display: flex;
        position: sticky;
        top: 0px;
        z-index: 600;
         
    }
    .desactive{
        height: 80px;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        padding-inline: 10px;
        background-color: rgba(2, 65, 142, 1);
        
        display: flex;
        position: sticky;
        top: 0px;
        z-index: 600;
         
    }
    
    .menuActive{
        width: 100%;
        height: 50vh;
        position: absolute;
        transition: 1s all ease-in;
        background-color: rgba(2, 65, 142, 1);
        opacity: 0.9;
        top: 80px;
        left: 0px;
        display: flex;
        flex-direction: column;
        z-index: 300;

        
    }
    .menuDesactive{
        opacity: 0.9;
    }
    .menuActive ul{
        position: relative;
        width: 200px;
        z-index: 200;
    }
    .menuActive ul li{
        position: relative;
        z-index: 200;
        text-decoration: none;
        list-style: none;
        
    }
    
    .menuDesactive ul li{
        position: relative;
        z-index: 200;
        text-decoration: none;
        list-style: none;
        
    }
    .what{
        text-decoration: none;
        color: rgba(165,165,165, 1);
    }
    .menuDesactive{
        margin: 0;
        width: 100%;
        height: 50vh;
        position: absolute;
        top: 80px;
        left: -100%;
        transition: 1s all ease-in;
        background-color: rgba(2, 65, 142, 1);
        display: flex;
        flex-direction: column;
        z-index: 300;

    }
    .menuImg{
        width: 50px;
        height: 35px;
        z-index: 600;
    }

    .logo{
        width: 60px;
        z-index: 600;
    }

.wrappActive{
    display: flex;
    flex-direction: column;
    position: relative;
    top: 0px;
    left: 0px;
    height: 135px;

}
.wrappDesactive{
 display: none;
}

.itemLista{
    margin-bottom: 10px;
    height: 25px;
    text-decoration: none;
    color: rgba(165,165,165, 1);
}


.itemLista:hover{
    color: rgba(165,165,165, 1);
    text-shadow:none;
}
.itemLista1:hover{
    color: rgba(165,165,165, 1);
    text-shadow: none;
}


.itemLista1 span{
    
    font-size: 13px;
    margin-right: -10px;
    color: rgba(165,165,165, 1);
   position: relative;
   width: 100px;
   right: 85px;
   top: 1px;
   
   text-align: end;
}
.itemLista1 span:hover{
    color: rgba(165,165,165, 1);
    text-shadow: none
}



}
@media screen and (max-height: 576px) {
    .menuActive{
        width: 100%;
        height: 100vh;
        position: absolute;
        transition: 1s all ease-in;
        background-color: rgba(2, 65, 142, 1);
        opacity: 0.9;
        top: 80px;
        left: 0px;
        display: flex;
        flex-direction: column;
        z-index: 300;

    }

    .menuDesactive{
        opacity: 0.9;

        margin: 0;
        width: 100%;
        height: 100vh;
        position: absolute;
        top: 80px;
        left: -150%;
        transition: 1s all ease-in;
        background-color: rgba(2, 65, 142, 1);
        display: flex;
        flex-direction: column;
        z-index: 300;

    }
}